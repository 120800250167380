import moment, { Moment } from "moment";

export const formatAndValidateDate = (dateString: string): Date | null => {
  // Parse the date using moment.js
  const parsedDate: Moment = moment(dateString, "YYYY-MM-DD", true);

  // Check if the parsed date is valid
  if (!parsedDate.isValid()) {
    return null;
  }
  
  // Create a Date object from the Moment object:
  const formattedDate = new Date(parsedDate.valueOf()); // Use .valueOf() to get a timestamp

  return formattedDate;
};


export const getFileSize = (size: number) => {
  const fileSizeInBytes = size;
  const fileSizeInKB = fileSizeInBytes / 1024;
  const fileSizeInMB = fileSizeInKB / 1024;

  return fileSizeInMB < 1
    ? `${fileSizeInKB.toFixed(2)} KB`
    : `${fileSizeInMB.toFixed(2)} MB`;
};
