import React, {ReactNode} from "react";
import moment from "moment";

interface ListItemProps {
    id: number,
    Icon: ReactNode;
    handleNavigate: (id: number, path: string) => void;
    title: string;
    highlightedText?: ReactNode;
    content: string;
    path: string;
}

const ListItem = ({Icon, content, title, id, handleNavigate, path, highlightedText}: ListItemProps) => {
    return (
        <div
            key={id}
            className="flex items-start  px-2 py-1 hover:bg-[#E8F2FD] dark:hover:bg-button-dark rounded"
        >
            <div className={"flex gap-3 items-center"}>
                <div className="w-[20px] h-[20px]">
                { Icon }

                </div>
                <div>
                    <div
                        className="text-b-secondary-dark font-extrabold text-[12px]  dark:text-b-secondary-light cursor-pointer"
                        onClick={() =>
                            handleNavigate(id, path)
                        }
                    >
                        {title}
                    </div>
                    <p className="text-[11px] mt-1">{highlightedText}</p>
                    <p className="text-[#888888] mt-1 text-[10px] text-wrap text-ellipsis">
                        {moment(content).format('[Updated] ddd, M/D/YYYY h:mmA')}
                    </p>

                </div>
            </div>
        </div>
    )
}

export default ListItem