// React
import React, { useEffect, useRef, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
import NotesForm from "../Components/Notes-Form";
// OpenAPI
import {
  NoteDomainApi,
  NoteSourceTypeDomainApi,
} from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Helpers
import { convertImagesToBase64 } from "../helpers/convertImageToBase64";
// Types
import { ErrorFileData } from "../interfaces/pages/Errors";
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";

const initialValues = {
  title: "",
  noteSourceTypeId: "",
  sourceDetail: "",
  content: "",
  commentary: "",
  sentiment: "",
  datePublished: new Date(),
  author1: "",
  author2: "",
  author3: "",
  tags: [],
  files: { add: [], delete: [] },
  domains: [],
};

const AddEditNote: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sourceType, setSourceType] = useState<any>([]);
  const [changedValues, setChangedValues] = useState<any>({});
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [editorData, setEditorData] = useState({ ref: null, keyName: "" });

  // Fetch note source type options on component mount
  useEffect(() => {
    fetchNoteSourceType();
  }, []);

  // Function to fetch note source type options
  const fetchNoteSourceType = async () => {
    try {
      const api = new NoteSourceTypeDomainApi(apiConfig());
      const response = await api.getNoteSourceType();
      setSourceType(response.data.content);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchNoteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchNoteData = async () => {
    setLoading(true);
    try {
      const api = new NoteDomainApi(apiConfig());

      const response = await api.getNoteById(String(id));
      if (response.data) {
        const data: any = response.data;
        data.datePublished = new Date(data.datePublished);

        data.tags =
          data.tags?.map((tag: any) => {
            return { value: tag.id, label: tag.tag };
          }) || null;
        data.domains = data.domains?.map((domain: any) => {
          return {
            label: `${domain.name} - ${domain.domainName}`,
            value: domain.id,
          };
        });
        delete data.mnaName;
        delete data.mnaSourceCategory;
        delete data.sourceType;
        data.old = data.files;

        formik.setValues(data);
        setChangedValues(data);
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      values[editorData.keyName] = editorData.ref;
      values.datePublished =
        formik.values["datePublished"] !== null
          ? new Date(values.datePublished).toISOString()
          : null;
      const content = await convertImagesToBase64(values.content);
      const data: any = {
        ...formik.values,
        version: 0,
        sentiment: values.sentiment ? Number(values.sentiment) : null,
        noteSourceTypeId: values.noteSourceTypeId
          ? Number(values.noteSourceTypeId)
          : null,
        datePublished: values?.datePublished,
        content,
      };

      if (data.tags?.length > 0) {
        const tags = data.tags.reduce(
          (result: any, obj: any) => {
            if (!changedValues.tags?.includes(obj)) {
              result.add = result.add || [];
              result.add = [...result.add, { tag: obj.label }];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.tags?.forEach((item: any) => {
          const deletedObj = data.tags?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            } else {
              return null;
            }
          });
          if (!deletedObj.includes(item.value)) {
            tags.delete = tags.delete || [];
            tags.delete = [...tags.delete, item.value];
          }
        });
        if (id) {
          data.tags = tags;
        } else {
          delete tags.delete;
          data.tags = tags?.add?.length > 0 ? tags : null;
        }
      } else {
        const tags: any = { add: null, delete: [] };
        tags.delete = changedValues.tags?.map((item: any) => item.value);
        data.tags = id ? tags : null;
      }

      if (data.domains?.length > 0) {
        const domains = data.domains.reduce(
          (result: any, obj: any) => {
            if (!changedValues.domains?.includes(obj)) {
              result.add = result.add || [];
              result.add = [
                ...result.add,
                { entityId: obj.value, typeId: obj.domainTypeId },
              ];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.domains?.forEach((item: any) => {
          const deletedObj = data.domains?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            } else {
              return null;
            }
          });

          if (!deletedObj.includes(item.value)) {
            domains.delete = domains.delete || [];
            domains.delete = [...domains.delete, item.value];
          }
        });
        if (id) {
          data.domains = domains;
        } else {
          delete domains.delete;
          data.domains = domains?.add?.length > 0 ? domains : null;
        }
      } else {
        const domains: any = { add: null, delete: [] };
        domains.delete = changedValues.domains?.map((item: any) => item.id);
        data.domains = id ? domains : null;
      }
      if (
        Object.keys(data.files).includes("add") ||
        Object.keys(data.files).includes("delete")
      ) {
      } else {
        data.files = { add: null, delete: null };
      }

      delete data.old;
      if (submitButtonRef.current && !submitButtonRef.current.disabled) {
        submitButtonRef.current.disabled = true;
        try {
          // API call
          const api = new NoteDomainApi(apiConfig());
          id
            ? await api.updateNote(String(id), data)
            : await api.createNote(data);
          navigate("/notes");
        } catch (error: any) {
          if (error.response) {
            values.datePublished =
              formik.values["datePublished"] !== null
                ? new Date(values.datePublished)
                : null;
            if (
              error.response.data?.exception &&
              !error.response.data?.fieldErrors?.length
            ) {
              toast.custom(
                (t) => (
                  <div
                    className={`${
                      t.visible ? "animate-enter" : "animate-leave"
                    } max-w-md w-full bg-danger shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                  >
                    <div className="flex-1 w-0 p-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 pt-0.5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="50"
                            height="50"
                            className="dark:brightness-[4]"
                            viewBox="0 0 48 48"
                          >
                            <path
                              fill="#F78F8F"
                              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
                            ></path>
                          </svg>
                        </div>
                        <div className="ml-3 flex-1 text-white">
                          <p>{error.response.data?.exception}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                {
                  duration: 500,
                }
              );
            }
            if (error.response.data?.fieldErrors?.length) {
              const errorsObject = {} as any;
              error.response.data?.fieldErrors.forEach(
                (error: ErrorFileData) => {
                  errorsObject[error.field] = error.defaultMsg;
                }
              );
              setErrors(errorsObject);
              setServerError(errorsObject);
            }
          } else {
            // Handle other errors
            console.error("Error submitting form:", error.message);
          }
        } finally {
          setSubmitting(false);
          if (submitButtonRef.current) {
            submitButtonRef.current.disabled = false;
          }
        }
      }
    },
  });

  const handleEditorRef = (ref: any) => {
    setEditorData(ref);
  };

  return (
    <React.Fragment>
      <div
        className={`py-[2rem] pr-[2rem] ${
          isMenuOpen ? "pl-[316px]" : "pl-[92px]"
        } duration-500`}
      >
        {" "}
        {/* Breadcrumbs component */}
        <Breadcrumbs breadcrumbs={[id ? "Update Note" : "Add New Note"]} />
        <div>
          {/* Form */}
          <form
            onSubmit={formik.handleSubmit}
            onChange={() => setServerError(null)}
          >
            <NotesForm
              formik={formik}
              serverError={serverError}
              sourceType={sourceType}
              onEditorRef={handleEditorRef}
            />
            <div className="flex justify-end gap-[30px] mt-[80px]">
              <div>
                <button
                  type="button"
                  className="py-[18px] border border-secondary text-secondary dark:bg-button-dark dark:text-white text-base font-medium rounded-full px-[116px]"
                  onClick={() => navigate("/notes")}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="button"
                  ref={submitButtonRef}
                  onClick={formik.handleSubmit}
                  name="Save"
                  className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default AddEditNote;
