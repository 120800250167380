// React
import React, { useEffect, useState } from "react";
// Third party
import { Link, useLocation, useParams } from "react-router-dom";
import { useTable, usePagination, useSortBy, useGlobalFilter, Column } from "react-table";
// API
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Types
import { Cell, ColumnTable, HeaderGroup, RowTable } from "../../interfaces/pages/variedInterfaces";
// Images
import Arrow from "../../Assets/svg/blue-arrow.svg";


interface FundamentalData {
  id: string;
  sourceType: string;
  sourceTypeId: number;
  sourceDetail: string;
  datePublished: string; 
  title: string;
  content: string;
  sentiment: number;
  author1: string;
  author2: string;
  author3: string;
}

interface TableProps {
  columns: Column[];
  caption?: string;
  additionalDataKey?: string; 
  openModal?: (value: any) => void;
  fetchData?: (pageIndex: number, pageSize: number, sortBy: any, globalFilter: string) => Promise<any>;
  dataFundamental?: FundamentalData[]; 
}


const Table: React.FC<TableProps> = ({ 
  columns, 
  caption, 
  fetchData, 
  additionalDataKey, 
  dataFundamental 
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [inputPage, setInputPage] = useState<number | string>("");
  const [totalElement, setTotalElement] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { globalFilter, pageIndex, pageSize, sortBy } = state;

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        if (fetchData) {
          const result = await fetchData(pageIndex, pageSize, sortBy, globalFilter);
          setData(result.content);
          setControlledPageCount(Math.ceil(result.page.totalPages));
          setTotalElement(result.page.totalElements); 
        } else if (dataFundamental) {
          setData(dataFundamental); 
          setControlledPageCount(1); 
          setTotalElement(dataFundamental.length); 
        }
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter, dataFundamental]);

  const handleSortToggle = (column: ColumnTable) => {
    const { canSort, toggleSortBy, isSortedDesc } = column;

    if (canSort) {
      toggleSortBy(!isSortedDesc, false);
    }
  };

  return (
    <div>
      {/* Table */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
              <table className="min-w-full dark:bg-primary-dark" {...getTableProps()}>
                {caption && (
                  <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px] mb-5">
                    {caption}
                  </caption>
                )}
                <thead
                  className={`${
                    "/dashboard" === pathname
                      ? "bg-table-sub-light dark:bg-black dark:text-white"
                      : pathname === `/mna/${id}`
                      ? "bg-table-sub-light"
                      : "bg-primary-default dark:bg-black"
                  }`}
                >
                  {headerGroups.map((headerGroup: HeaderGroup, index: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column: any, index: number) => (
                        <th
                          key={index}
                          onClick={() => handleSortToggle(column)}
                          scope="col"
                          className={`px-[29px] py-3 text-start font-semibold text-lg ${
                            "/dashboard" === pathname
                              ? "bg-table-sub-light dark:bg-black dark:text-white text-primary py-3"
                              : pathname === `/mna/${id}`
                              ? "bg-table-sub-light text-sub-table-light dark:bg-black dark:text-white"
                              : "bg-primary-default dark:bg-black text-[#fff] py-[19px]"
                          }`}
                        >
                          {column.render("Header")}
                          <span>{column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : null}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {data.length > 0 ? (
                    page.map((row: RowTable, index: number) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={index}>
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: Cell) => (
                              <td
                                {...cell.getCellProps()}
                                className="align-top px-[30px] py-[17px] text-[#393C51] dark:text-white font-normal text-base"
                              >
                                {cell.render("Cell")}{" "}
                              </td>
                            ))}
                          </tr>
                          {additionalDataKey && (
                            <tr
                              key={`additional-${index}`}
                              className={`${index === page.length - 1 ? "" : "border-b border-[#D3D3E6] "}`}
                            >
                              <td
                                colSpan={columns.length}
                                className="px-[30px] text-[#696868] dark:text-white font-normal text-base truncate"
                              >
                                <Link to={`/notes/${row.original.id}`}>
                                  <button
                                    type="button"
                                    className="text-secondary pb-[26px] font-bold underline float-right dark:text-white"
                                  >
                                    Read more
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={columns?.length} className="text-primary font-bold text-base p-4">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="flex flex-col md:flex-row justify-between mt-6">
              <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
                <div className="mb-2 md:mb-0">
                  <span className="text-primary text-lg font-medium border-r border-primary pr-5">
                    Showing {data.length !== 0 && pageIndex * pageSize + 1} {data.length !== 0 && "-"}{" "}
                    {pageIndex === pageCount - 1
                      ? Math.min((pageIndex + 1) * pageSize, totalElement)
                      : Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
                    of {totalElement}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-primary text-sm font-normal md:ml-5">Show Entries</div>
                  <div className="relative">
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                      className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border border-primary rounded-full px-[22px] mr-4 py-[12px] text-primary appearance-none outline-none cursor-pointer w-[82px]"
                    >
                      {[10, 20, 30, 40, 50].map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                    <span className="absolute top-1/2 right-8 -translate-y-1/2">
                      <img src={Arrow} alt="dropdown" className="" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-secondary flex items-center mt-4 md:mt-0">
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="mr-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    className="dark:brightness-[4]"
                  >
                    <path
                      d="M9.1233 9.87681L12.1644 6.83576L10.6438 5.31524L6.08228 9.87681L10.6438 14.4385L12.1644 12.9179L9.1233 9.87681Z"
                      fill="#F3797E"
                    />
                  </svg>
                </button>
                {Array.from({ length: pageCount }, (_, i) => {
                  const pageNumber = i + 1; 
                  if (pageNumber > 0) {
                    if (pageCount > 4) {
                      if (
                        (pageNumber >= pageIndex && pageNumber <= pageIndex + 2) ||
                        pageNumber === 1 ||
                        pageNumber === pageCount
                      ) {
                        return (
                          <button
                            key={i}
                            onClick={() => gotoPage(pageNumber - 1)} 
                            className={`px-2 text-secondary dark:text-primary-light ${pageNumber === pageIndex + 1 ? "border-b-2" : ""}`}
                          >
                            <span>{pageNumber}</span>
                          </button>
                        );
                      } else if (
                        (pageIndex >= 3 && pageNumber === 2) ||
                        (pageIndex < pageCount - 2 && pageNumber === pageCount - 1)
                      ) {
                        return (
                          <span key={`ellipsis${i}`} className="p-2 text-secondary dark:text-primary-light">
                            ...
                          </span>
                        );
                      }
                    } else {
                      return (
                        <button
                          key={i}
                          onClick={() => gotoPage(pageNumber - 1)}
                          className={`px-2 text-secondary dark:text-primary-light ${pageNumber === pageIndex + 1 ? "border-b-2" : ""}`}
                        >
                          <span>{pageNumber}</span>
                        </button>
                      );
                    }
                  }
                  return null;
                })}

                <div className="flex items-center">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      gotoPage(Number(inputPage) - 1);
                      // setInputPage("");
                    }}
                  >
                    <button className="hidden md:inline-block text-secondary dark:text-primary-light" type="submit">
                      Go{" "}
                    </button>
                    <input
                      type="number"
                      className="border border-[#EDEDF7] bg-[#ffffff] rounded-md mx-2 max-w-[50px] outline-none px-2 dark:bg-primary-dark text-secondary dark:text-primary-light"
                      value={inputPage}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setInputPage(inputValue);
                      }}
                    />
                  </form>
                </div>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    className="dark:brightness-[4]"
                  >
                    <path
                      d="M9.5537 9.87681L6.51257 6.83576L8.03319 5.31524L12.5947 9.87681L8.03319 14.4385L6.51257 12.9179L9.5537 9.87681Z"
                      fill="#F3797E"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
