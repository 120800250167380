// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table from "../Components/Tables/Mna-Table";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { FundamentalDomainApi } from "../openapi";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort, HandleMenuProps } from "../interfaces/pages/variedInterfaces";
// Images
import fundamentalIcon from "../Assets/svg/blue-fundamental.svg";

  const FundamentalList: React.FC<HandleMenuProps> = ({
    isMenuOpen,
    setIsMenuOpen,
  }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search] = useState("");


  const fetchFundamentalList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    setLoading(true);
    try {
      const api = new FundamentalDomainApi(apiConfig());

      const sortArray = getSortArray(sortBy);

      const response = await api.getFundamentals(pageIndex, pageSize, sortArray);

      const content = new getApiData();
      const data = await content.getSectorDomainApi(response);
      return { ...response.data, data };
    } catch (error) {
      expiredTokenValidation(error);
    } finally {
      setLoading(false);
    }
  }, [search]);

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div
        className={`py-[30px] pr-[30px] ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Fundamental"]}
          icon={fundamentalIcon}
          addItem={() => navigate("/add-fundamental")}
          // onFilter={(value) => setSearch(value)}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.FundamentalList(navigate)}
            fetchData={fetchFundamentalList}
            loading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FundamentalList;
