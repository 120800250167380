import axios, { AxiosInstance } from "axios";
import { Configuration } from "../../openapi";

export const apiConfig = () => {

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const setAuthorizationHeader = () => {
    const token = localStorage.getItem("token");
    if (token) {
      axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
    }
  };

  setAuthorizationHeader();

  // Set up request interceptor to attach token on every request
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      console.log(error)
      Promise.reject(error)
    }

  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.httpStatus === 401 || error.response &&
        error.response.exception === "TokenExpiredException"
      ) {
        originalRequest._retry = true; 
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          const userId = localStorage.getItem("userId");

          if (refreshToken && userId) {
            const response = await axios.post("/refresh-token", {
              refreshToken,
              userId,
            });

            const { accessToken, refreshToken: newRefreshToken } = response.data;
            localStorage.setItem("token", accessToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axiosInstance(originalRequest);
          }
        } catch (error: any) {
          if (
            error.response &&
            error.response.data.message.includes("Refresh token was expired")
          ) {
            window.location.href = "/";
          } else {
            console.log("An error occurred while refreshing the token.");
          }
        }
      }
      return Promise.reject(error);
    }
  );

  // Create a custom configuration object to return
  const customConfig: Configuration = {
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: axiosInstance.defaults, // Use Axios instance defaults as base options
    isJsonMime: (mime: string) => {
      const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
      return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    },
  };

  return customConfig;
};
