import { FormikValues } from "formik";
import React from "react";

interface Option {
  value: string;
  label: string;
}

interface RadioButtonProps {
  label?: string;
  id: any;
  name: any;
  serverError?: any;
  formik?: {
    values: FormikValues;
    setFieldValue: (field: string, value: string[]) => void;
    setValues: (value: string[]) => void;
    handleBlur: () => void;
    handleChange: () => void;
    errors: {
      [key: string]: string | undefined;
    };
    touched: {
      [key: string]: boolean | undefined;
    };
  };
  required?: boolean;
  last?: boolean;
  options: Option[];
  selectedOption: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  id,
  name,
  serverError,
  formik,
  required,
  last,
  options,
  selectedOption,
}) => {
  
  return (
    <React.Fragment>
      <div className={`flex justify-between items-center ${!label && "mb-2"}`}>
        <label
          htmlFor={id}
          className={`text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1 `}
        >
          {label} {required && <span className="text-[#DB1A21]">*</span>}
        </label>

        <div id={id} className={` ${last ? "tooltip2" : "tooltip"}`}>
          <div
            id={`tooltipText-${id}`}
            className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50  w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border  cursor-pointer dark:before:border-t-primary-dark ${
              serverError && serverError[id]
                ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
                : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
            } ${last ? " tooltiptext2" : "tooltiptext"}`}
          >
            {serverError && serverError[id]
              ? serverError[id]
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            className="dark:brightness-[4]"
          >
            <path
              d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
              fill={`${serverError && serverError[id] ? "#DB1A21" : "#1E87F0"}`}
            />
            <path
              d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
              fill={` ${
                serverError && serverError[id] ? "#DB1A21" : "#1E87F0"
              }`}
            />
          </svg>{" "}
        </div>
      </div>
      <ul className="items-center w-full  h-auto text-sm font-medium  bg-[#fff] border border-[#C4C1DE] rounded-full sm:flex dark:bg-primary-dark dark:border-primary-light dark:text-white">
        {options?.map((option: any, index) => (
          <li
            className={`w-full h-full border-b border-[#C4C1DE] sm:border-b-0 dark:border-[#C4C1DE] ${
              index !== options.length - 1 && "sm:border-r"
            }`}
            key={index}
          >
            <label
              htmlFor={option.value}
              className="flex cursor-pointer select-none items-center text-[#7B7B7B] justify-center py-5 dark:text-white"
            >
              <div className="relative">
                <input
                  id={option.value}
                  name={name}
                  type="radio"
                  value={option.value}
                  onChange={formik?.handleChange}
                  className="sr-only"
                />
                <div
                  className={`box mr-2 flex h-4 w-4 items-center justify-center rounded-full border border-[#49AAFD] ${
                    selectedOption == option.value &&
                    "border-[5px] border-[#49AAFD]"
                  } `}
                >
                  <span className="h-2.5 w-2.5 rounded-full dark:bg-primary-dark "></span>
                </div>
              </div>
              {option.label}
            </label>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default RadioButton;
