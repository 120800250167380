// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import { Columns } from "../Components/Columns/Columns";
import Table from "../Components/Tables/Mna-Table";
// openAPI
import { getApiData } from "../Controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Types
import { Sort, HandleMenuProps } from "../interfaces/pages/variedInterfaces";
// Images
import errorLogIcon from "../Assets/svg/blue-admin.svg";
import DeleteModal from "../Components/Delete-Modal";
import { ErrorLogDomainApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";

  const ErrorList: React.FC<HandleMenuProps> = ({
    isMenuOpen,
    setIsMenuOpen,
  }) => {
  const [search] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState<any>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedItemID, setDeletedItemID] = useState<boolean>(false);
  let update = true

  const fetchErrorList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
     if(update){
      setLoading(true);
      try {
        const api = new getApiData();
        return await api.getErrorLogDomainApi(pageSize,pageIndex,sortBy);
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
        if(deleteID){
          update = false
        }
      }
     }
    }, [deletedItemID]
  );

  const handleDeleteError = async () => {
    try {
      const api = new ErrorLogDomainApi(apiConfig());
      await api.deleteErrorLogById(String(deleteID));
      update = true
      setDeletedItemID(!deletedItemID)
      setDeleteID('')
    } catch (error) {
      expiredTokenValidation(error);
    } finally {
      setDeleteModal(false);
    }
  };

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
        <div
        className={`py-[30px] pr-[30px] ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Error Logs"]}
          icon={errorLogIcon}
          addItem={() => navigate("/add-mna")}
          // onFilter={(value) => setSearch(value)}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.ErrorList(navigate, deleteModal,setDeleteModal, setDeleteID)}
            fetchData={fetchErrorList}
            search={search}
            loading={loading}
          />
        </div>
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          onDeleteItem={handleDeleteError}
        />
      </div>
    </React.Fragment>
  );
};

export default ErrorList;
