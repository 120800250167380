// React
import { useRef } from "react";
// Third party
import ReactQuill from "react-quill";
// Components
import DeleteTooltip from "../../Delete-Tooltip";
import TextEditor from "../../TextEditor";

// Types
import { StepBase } from "..";
import InputField from "../../Common-inputs";

interface StepEightProps extends StepBase {
  Minus: string;
  Plus: string;
  txDocSummariesList:any
}

export default function StepEight(props: StepEightProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    setAssociation,
    setDeleteID,
    setDeleteModal,
    txDocSummariesList,
    serverError,
    onEditorRef
  } = props;
  const $txDocSummaries = useRef<ReactQuill>(null);
  // Add entry
  const handleAddEntry = () => {
    formik.setValues({
      ...formik.values,
      txDocSummaries: [...formik.values.txDocSummaries, { summary: "", typeId: "", mnaId:  "0" }],
    });
  };
  
  return (
    <>
      <div
        className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light"
        onKeyDownCapture={(e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            e.stopPropagation();
            $txDocSummaries.current && $txDocSummaries.current.focus();
          }
        }}
      >
        <div className="px-[23px] py-[30px]">
          <div>
            {/* What Matters */}
            {formik.values.txDocSummaries?.map((entry: any, index: number) => (
              <div key={index} className="w-full mb-6">
                <div className="w-full mb-4">
                  <InputField
                    label="Type"
                    id={`txDocSummaries[${index}].typeId`}
                    name={`txDocSummaries[${index}].typeId`}
                    type="select"
                    placeholder="Select"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    optionList={txDocSummariesList || []}
                    labelKey="name"
                    valueKey="id"
                    isDynamic={true}
                    value={entry.typeId}
                    last
                  />
                </div>
                <div>
                  <TextEditor
                    formik={formik}
                    id={`txDocSummaries[${index}].summary`}
                    quillRef={$txDocSummaries}
                    name={`txDocSummaries[${index}].summary`}
                    isDynamic
                    value={entry.summary}
                    label="Summary"
                    onEditorRef={onEditorRef}
                  />
                  <div className="relative mt-1">
                    <button
                      onClick={() => {
                        setDeleteID(index);
                        setAssociation("txDocSummaries");
                        setDeleteModal(true);
                        // handleRemoveEntry(index)
                      }}
                      type="button"
                      className="text-secondary text-base font-medium flex items-center gap-[6px]"
                    >
                      <img src={Minus} alt="minus" /> Remove
                    </button>
                    {deleteID === index && association === "txDocSummaries" && (
                      <DeleteTooltip
                        tooltip={deleteModal}
                        setTooltip={setDeleteModal}
                        onDeleteItem={handleRemoveAssociation}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center">
            <button
              type="button"
              data-testid="Addnew"
              onClick={() => {
                handleAddEntry();
              }}
              className="text-secondary text-lg py-4 rounded-lg font-bold flex items-center justify-center  mt-4 border border-[#C3C1DF] w-1/6 bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
            >
              <img src={Plus} alt="plus" className="dark:brightness-200" /> Add
              new
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
