import AddEditFundamental from "../Pages/Add-Edit-fundamental";
import AddEditMna from "../Pages/Add-Edit-mna";
import AddEditNote from "../Pages/Add-Edit-note";
import AddEditRegulatory from "../Pages/Add-Edit-regulatory";
import AlertList from "../Pages/Alerts-list";
import Dashboard from "../Pages/Dashboard";
import ErrorDetails from "../Pages/Error-Details";
import ErrorList from "../Pages/Error-list";
import FileList from "../Pages/File-list";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import FundamentalDetails from "../Pages/Fundamental-Details";
import FundamentalList from "../Pages/Fundamental-list";
import MnaDetails from "../Pages/Mna-Details";
import MnaList from "../Pages/Mna-list";
import NoteDetails from "../Pages/Note-Details";
import NoteList from "../Pages/Note-list";
import RegulatoryDetails from "../Pages/Regulatory-Details";
import RegulatoryList from "../Pages/Regulatory-list";
import Login from "../Pages/Auth/Login";
import ResetPassword from "../Pages/Auth/ResetPassword";
import ValidateToken from "../Pages/Auth/ValidateToken";
import DataMigration from "../Pages/Data-migration";
import AddEditMnaMiniForm from "../Pages/Add-Edit-mna-mini-form";

interface Route {
  path: string;
  element: React.ComponentType<any>;
}

interface Route {
  path: string;
  element: React.ComponentType<any>;
}

export const protectedRoutes: Route[] = [
  {
    path: "/dashboard",
    element: (props: any) => <Dashboard {...props} />,
  },
  {
    path: "/real-time-entries",
    element: (props: any) => <Dashboard {...props} />,
  },
  {
    path: "/notes",
    element: (props: any) => <NoteList {...props} />,
  },
  {
    path: "/notes/add",
    element: (props: any) => <AddEditNote {...props} />,
  },
  {
    path: "/notes/:id",
    element: (props: any) => <NoteDetails {...props} />,
  },
  {
    path: "/notes/:id/edit",
    element: (props: any) => <AddEditNote {...props} />,
  },
  {
    path: "/add-mna",
    element: (props: any) => <AddEditMna {...props} />,
  },
  {
    path: "/update-mna/:id",
    element: (props: any) => <AddEditMna {...props} />,
  },
  {
    path: "/add-mna/:id",
    element: (props: any) => <AddEditMna {...props} />,
  },
  {
    path: "/mnas",
    element: (props: any) => <MnaList {...props} />,
  },
  {
    path: "/mna/:id",
    element: (props: any) => <MnaDetails {...props} />,
  },
  {
    path: "/regulatory-library",
    element: (props: any) => <Dashboard {...props} />,
  },
  {
    path: "/files",
    element: (props: any) => <FileList {...props} />,
  },
  {
    path: "/error",
    element: (props: any) => <ErrorList {...props} />,
  },
  {
    path: "/error/:id",
    element: (props: any) => <ErrorDetails {...props} />,
  },
  {
    path: "/data-migration",
    element: (props: any) => <DataMigration {...props} />,
  },
  {
    path: "/alerts",
    element: (props: any) => <AlertList {...props} />,
  },
  {
    path: "/fundamental",
    element: (props: any) => <FundamentalList {...props} />,
  },
  {
    path: "/add-fundamental",
    element: (props: any) => <AddEditFundamental {...props} />,
  },
  {
    path: "/update-fundamental/:id",
    element: (props: any) => <AddEditFundamental {...props} />,
  },
  {
    path: "/fundamental/:id",
    element: (props: any) => <FundamentalDetails {...props} />,
  },
  {
    path: "/regulatory",
    element: (props: any) => <RegulatoryList {...props} />,
  },
  {
    path: "/regulatory/add",
    element: (props: any) => <AddEditRegulatory {...props} />,
  },
  {
    path: "/regulatory/:id",
    element: (props: any) => <RegulatoryDetails {...props} />,
  },
  {
    path: "/update-regulatory/:id",
    element: (props: any) => <AddEditRegulatory {...props} />,
  },
];

export const publicRoutes: Route[] = [
  {
    path: "/",
    element: (props: any) => <Login {...props} />,
  },
  {
    path: "/forgot-password",
    element: (props: any) => <ForgotPassword {...props} />,
  },
  {
    path: "/reset/",
    element: (props: any) => <ValidateToken {...props} />,
  },
  {
    path: "/reset-password",
    element: (props: any) => <ResetPassword {...props} />,
  },
];
