import { useEffect } from 'react';

const useServiceWorkerUpdate = () => {
    useEffect(() => {
        if ('serviceWorker' in navigator) {

            navigator.serviceWorker.ready.then((registration) => {
                registration.active?.postMessage('checkForUpdate');
            });

            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data?.type === 'newVersionAvailable') {
                    const newVersion = event.data.version;
                    const currentVersion = localStorage.getItem('app_version');

                    if (!currentVersion || currentVersion !== newVersion) {
                        if (window.confirm(`New version (${newVersion}) available. Refresh to update?`)) {
                            localStorage.setItem('app_version', newVersion);

                            window.location.reload();
                        }
                    }
                }
            });
        }
    }, []);
};

export default useServiceWorkerUpdate;
