import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { UserProfileDomainApi } from "../../openapi";
import InputField from "../Common-inputs";
import { CustomErrorModal, CustomSuccessModal } from "../Modal/customModal";
import toast from "react-hot-toast";
import { ErrorFileData } from "../../interfaces/pages/Errors";
import { apiConfig } from "../ConfigurationApi/Configuration";
import Loader from "../Loader";
import { setUsername } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState<any>(null);
  const id = localStorage.getItem('userId')  
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() =>{
    fetchProfileData()
  }, [])

  const fetchProfileData = async() => {
    setLoading(true)
    const api = new UserProfileDomainApi(apiConfig());
    const response = await  api.whoAmI()
    formik.setValues(response.data.profile || {})

    // Extract profile data and fallback to username if necessary
    const profile: any= response?.data?.profile || {};
    const nameFirst = profile.nameFirst || "";
    const nameLast = profile.nameLast || "";
    const name = nameFirst && nameLast ? `${nameFirst} ${nameLast}` : response?.data.username || "";
    dispatch(setUsername(name));
    setLoading(false)
  }


  const formik: any = useFormik({
    initialValues: { nameFirst: "", nameMiddle: "", nameLast: "", email: "" },
    onSubmit: async (values, { setErrors }) => {
      try {
        setLoading(true);

        const api = new UserProfileDomainApi(apiConfig());
        const response = await api.updateUserProfile(String(id), values) as any;
        if (response.status === 200) {
          fetchProfileData()
          toast.custom(
            (t) => <CustomSuccessModal  visible={t.visible}  message={"Record update successfully"} />,
            {
              duration: 500,
            }
          );
        }
      } catch (error: any) {
        if (error.response) {
          if (
            error.response.data?.exception &&
            !error.response.data?.fieldErrors?.length
          ) {
            toast.custom(
              (t) => <CustomErrorModal visible={t.visible} error={error} />,
              {
                duration: 500,
              }
            );
          }

          if (error.response.data?.fieldErrors?.length) {
            const errorsObject = {} as any;
            error.response.data?.fieldErrors.forEach((error: ErrorFileData) => {
              errorsObject[error.field] = error.defaultMsg;
            });
            setErrors(errorsObject);
            setServerError(errorsObject);
          }
        } else {
          // Handle other errors
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="p-5">
      <form className="w-full" onSubmit={formik.handleSubmit} onChange={() => setServerError(null)}>
        <h2 className="text-primary-dark text-[35px] font-bold uppercase mb-6 dark:text-white">
          Profile
        </h2>
        <div className="w-full mb-3">
          <InputField
            label="First Name"
            id="nameFirst"
            name="nameFirst"
            type="text"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            required
            last
          />
        </div>
        <div className="w-full mb-3">
          <InputField
            label="Middle Name"
            id="nameMiddle"
            name="nameMiddle"
            type="text"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            last
          />
        </div>
        <div className="w-full mb-3">
          <InputField
            label="Last Name"
            id="nameLast"
            name="nameLast"
            type="text"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            required
            last
          />
        </div>

        <div className="w-full mb-3">
          <InputField
            label="Email"
            id="email"
            name="email"
            type="email"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            required
            last
          />
        </div>
        <div className="mb-[40px] mt-4">
          <button
            type="submit"
            disabled={loading}
            className="w-full  text-[#fff] bg-secondary py-[18px] rounded-full hover:bg-primary-default dark:bg-black"
          >
            Update
          </button>
        </div>
      </form>
    </div>
          {loading && <Loader loading={loading} />}
  </React.Fragment>
  );
};

export default Profile;
