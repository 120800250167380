import { Status } from "../../Pages/Data-migration";


interface MigrationButtonProps {
  item: { id: string; title: string; icon: string; apiEndpoint: string };
  status: Status;
  startMigration: (domain: string) => void;
}

export function MigrationButton({
  item,
  status,
  startMigration,
}: MigrationButtonProps) {
  const display = {
    loading: "Migration in progress",
    completed: "Migration Completed",
    "not-started": "Start Migration",
    failed: "Migration Failed",
  };

  return (
    <div className="border border-[#E3E2E9] flex flex-col gap-10 p-3 rounded-[4px]">
      <div className="flex items-center gap-2.5">
        <img src={item.icon} alt="icon" className="w-8 h-8" />
        <p className="text-[#181825] text-sm">{item.title}</p>
      </div>
      <button
        className="flex gap-1 text-sm text-[#3B97FF] cursor-pointer"
        onClick={() => startMigration(item.title)}
        disabled={status === "loading" || status === "completed"}
      >
        <p className="text-sm ">{display[status]}</p>
        {status === "not-started" ? (
          <img src={"/arrow-top-right.svg"} alt="arrow" />
        ) : null}
      </button>
    </div>
  );
}
