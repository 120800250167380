import React from "react";

const ListTitle = ({ title }: { title: string }) => {
    return (
        <div className="text-sm font-semibold text-[#181825] dark:text-table-light border-b border-b-b-gray pb-2">
            {title}
        </div>
    )
}

export default ListTitle;