// React
import React from "react";
// Third party
import { FormikValues } from "formik";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Types
import { OptionItem } from "../../interfaces/pages/variedInterfaces";
// Images
import DateIcon from "../../Assets/svg/date.svg";
import Dropdown from "../../Assets/svg/dropdown.svg";
import Keyboard from "../../Assets/svg/keyboard2.svg";
// Helper function to format date to ISO string
const formatDateToISOString = (date: Date, time: any): string => {
  if (time) {
    return date.toISOString();
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
interface InputFieldProps {
  label?: string;
  id: any;
  name: any;
  type: "text" | "date" | "textarea" | "select" | "email";
  placeholder: string;
  serverError?: any;
  formik?: {
    values: FormikValues;
    setFieldValue: (field: string, value: any) => void;
    setValues: (value: string[]) => void;
    handleBlur: () => void;
    handleChange: () => void;
    errors: {
      [key: string]: string | undefined;
    };
    touched: {
      [key: string]: boolean | undefined;
    };
  };
  showIcon?: boolean;
  rows?: number;
  required?: boolean;
  last?: boolean;
  optionList?: any;
  labelKey?: string;
  valueKey?: string;
  bgColor?: string;
  time?: boolean;
  onChange?: ((value: any) => void) | undefined;
  isDynamic?: boolean;
  value?: any;
  showAdditionalComment?: boolean;
  onBlur?: string;
  className?: string;
  maxlength?: number;
  onKeyDownCapture?: React.KeyboardEventHandler<HTMLDivElement> | undefined
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  id,
  name,
  type,
  placeholder,
  serverError,
  formik,
  showIcon,
  rows,
  required,
  last,
  optionList,
  labelKey,
  valueKey,
  bgColor,
  time,
  isDynamic,
  value,
  onChange,
  maxlength,
  onKeyDownCapture
}) => {

  let formattedDate: string = "";
  let selectedFormattedDate: Date | null = null;

  if (type === "date") {
    const dateString = formik?.values[id] || value || "";
    if (dateString) {
      const date = new Date(dateString);

      if (time) {
        formattedDate = date.toISOString();
      } else {
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        formattedDate = `${month}/${day}/${year}`;
      }

      selectedFormattedDate = new Date(formattedDate);
    }
  }
  return (
    <React.Fragment>
      <div className={`flex justify-between items-center ${!label && "mb-2"}`}>
        <label
          className={`text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1 `}
        >
          {label} {required && <span className="text-[#DB1A21]">*</span>}
        </label>

        <div id={`tooltip-${id}`} className={` ${last ? "tooltip2" : "tooltip"}`}>
          <div
            id={`tooltipText-${id}`}
            className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50  w-[328px] px-[14px] pt-[8px] pb-[8px] rounded-[10px] border  cursor-pointer dark:before:border-t-primary-dark ${serverError && serverError[id]
              ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
              : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
              } ${last ? " tooltiptext2" : "tooltiptext"}`}
          >
            {serverError && serverError[id]
              ? serverError[id]
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            className="dark:brightness-[4]"
          >
            <path
              d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
              fill={`${serverError && serverError[id] ? "#DB1A21" : "#1E87F0"}`}
            />
            <path
              d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
              fill={` ${serverError && serverError[id] ? "#DB1A21" : "#1E87F0"
                }`}
            />
          </svg>{" "}
        </div>
      </div>

      {type === "text" ? (
        <div className="relative" onKeyDownCapture={onKeyDownCapture}>
          <input
            id={id}
            name={name}
            type="text"
            maxLength={maxlength}
            placeholder={placeholder}
            value={isDynamic ? (value || "") : (formik?.values ? formik?.values[name] : "")}
            onChange={onChange ? onChange : (formik && formik.handleChange)}
            onBlur={formik?.handleBlur}
            className={`py-[18px] px-[30px] border  rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none ${serverError && serverError[id] ? "border-danger dark:border-danger" : "border-[#C3C1DF] dark:border-white"
              } bg-[${bgColor ? bgColor : "#FAFAFE"}] dark:bg-primary-dark dark:text-white  ${showIcon && "pr-[50px]"}`}
          />
          {showIcon && (
            <span className="absolute right-7 top-5">
              <img src={Keyboard} alt="keyboard" />
            </span>
          )}
        </div>
      ) : type === "date" ? (
        // THE DATE FORMAT SHOULD BE ALWAYS
        // MM/dd/yyyy HH:mm:ss am/pm
        <ReactDatePicker
          id={id}
          name={name}
          selected={selectedFormattedDate}
          placeholderText={placeholder}
          className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full bg-[${bgColor ? bgColor : "#FAFAFE"
            }] outline-none ${serverError && serverError[id] ? "border-danger dark:border-danger" : "border-[#C3C1DF] dark:border-white"
            }`}
          onChange={(date: Date) => {
            const formattedDate = date ? formatDateToISOString(date, time) : "";
            formik?.setFieldValue(id, formattedDate);
            if (onChange) onChange(formattedDate);
          }}
          onBlur={formik?.handleBlur}
          icon={<img src={DateIcon} alt="date" />}
          dateFormat={time ? "yyyy/MM/dd h:mm aa" : "MM/dd/yyyy"}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          showTimeSelect={time}
          timeFormat="HH:mm"
          timeIntervals={5}
          timeCaption="time"
        />
      ) : type === "textarea" ? (
        <div className="relative">
          <textarea
            id={id}
            name={name}
            value={isDynamic ? (value || "") : (formik?.values ? formik?.values[name] : "")}
            rows={rows}
            placeholder={placeholder}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            className={`py-[18px] px-[30px] border  dark:text-white dark:placeholder:text-white dark:bg-primary-dark  rounded-lg text-base text-[#7B7B7B] font-light w-full bg-[${bgColor ? bgColor : "#FAFAFE"
              }] outline-none  ${serverError && serverError[id] ? "border-danger dark:border-danger" : " border-[#C3C1DF] dark:border-white"
              } ${showIcon && "pr-[50px]"}`}
          />
          {showIcon && (
            <span className="absolute right-7 top-5">
              <img src={Keyboard} alt="keyboard" />
            </span>
          )}
        </div>
      ) : type === "select" ? (
        <div className="relative">
          <select
            id={id}
            name={name}
            value={isDynamic ? (value || "") : (formik?.values ? formik?.values[name] : "")}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            className={`appearance-none py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full bg-[${bgColor ? bgColor : "#FAFAFE"
              }] outline-none  ${serverError && serverError[id] ? "border-danger dark:border-danger" : " border-[#C3C1DF] dark:border-white"
              } dark:text-white dark:placeholder:text-white dark:bg-primary-dark `}
          >
            <option value="">{placeholder}</option>
            {optionList.map((item: OptionItem, index: number) => (
              <option key={index} value={valueKey && item[valueKey]}>
                {labelKey && item[labelKey]}
              </option>
            ))}
          </select>
          <span className="absolute top-1/2 right-8  -translate-y-1/2">
            <img src={Dropdown} alt="dropdown" />
          </span>
        </div>
      ) : type === "email" ? (
        <div className="relative">
          <input
            id={id}
            name={name}
            type="email"
            placeholder={placeholder}
            value={isDynamic ? value || "" : formik?.values ? formik?.values[name] : ""}
            onChange={onChange ? onChange : formik && formik.handleChange}
            onBlur={formik?.handleBlur}
            className={`py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none ${serverError && serverError[id] ? "border-danger dark:border-danger" : "border-[#C3C1DF] dark:border-white"
              } bg-[${bgColor ? bgColor : "#FAFAFE"
              }] dark:bg-primary-dark dark:text-white  ${showIcon && "pr-[50px]"}`}
          />
          {showIcon && (
            <span className="absolute right-7 top-5">
              <img src={Keyboard} alt="keyboard" />
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      {formik?.touched[id] &&
        formik?.errors[id] &&
        !serverError &&
        !serverError[id] && (
          <div className="text-danger text-sm">{formik.errors[id]}</div>
        )}
    </React.Fragment>
  );
};

export default InputField;
