import {
    FilterByDomainsList,
    FundamentalIcon,
    LastUpdatedButtonsList,
    ListItem,
    ListTitle,
    MnaIcon,
    NotesIcon,
} from "./index";

import React, {ReactNode, useEffect, useRef} from "react";
import type {SearchResults} from "../../../interfaces/pages/SearchResults";
import {useNavigate} from "react-router-dom";
import {GlobalSearchFiltersEnum} from "../../../openapi";

interface Props {
  searchResults: SearchResults;
  showHistory: boolean;
  loading: boolean;
  query: string;
  searchHistory: string[];
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => Promise<void>;
  handleRemoveHistoryItem: (itemToRemove: any) => void;
  autocompleteSuggestions: any[];
  handleAutocomplete: (suggestion: any) => void;
  debouncedQuery: any;
  setSearchResults: React.Dispatch<any>;
  setFilters: React.Dispatch<React.SetStateAction<Set<GlobalSearchFiltersEnum>>>;
  filters: Set<GlobalSearchFiltersEnum>;
  setDateFilter: React.Dispatch<React.SetStateAction<string>>;
  dateFilter: string;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListSearch = ({
  searchResults,
  searchHistory,
  loading,
  showHistory,
  query,
  setQuery,
  handleSearch,
  handleRemoveHistoryItem,
  autocompleteSuggestions,
  handleAutocomplete,
  debouncedQuery,
  setSearchResults,
  setFilters,
  filters,
  setDateFilter,
  dateFilter,
  setShowHistory,
}: Props) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleNavigate = (id: number, path: string) => {
    navigate(`/${path}/${id}`);
    setSearchResults(false);
    setShowHistory(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setShowHistory(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowHistory(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const highlightText = (text: string) => {
    if (!text) return null;

    // Match the text surrounded by <em> tags and keep the rest
    const parts = text.split(/(<em>.*?<\/em>)/gi);

    return (
      <>
        {parts.map((part, index) => {
          if (/<em>(.*?)<\/em>/.test(part)) {
            const highlightedText = part.replace(/<\/?em>/g, "");
            return (
              <span key={index} style={{ backgroundColor: 'yellow' }}>
                {highlightedText}
              </span>
            );
          }
          // Return the normal text if it doesn't contain <em> tags
          return <span key={index}>{part}</span>;
        })}
      </>
    );
  };



  return (
    <>
      {showHistory && (
        <div
          ref={containerRef}
          className={`relative top-3 ${loading && " bg-white dark:bg-b-secondary-dark rounded-md "
            }`}
        >
          <div
            className={` max-h-96 overflow-y-auto absolute w-full  z-50 ${showHistory || searchResults
              ? "bg-white dark:bg-b-secondary-dark rounded-md border-t border-[#E6E6F9]"
              : ""
              }`}
          >
            {/* {searchHistory?.length === 0 ? (
              <div className="p-4 text-gray-500 text-sm  text-center">
                No recent searches
              </div>
            ) : (
              searchHistory
                .filter((item: string) => item.trim() !== "")
                .map((item: string, index: number) => (
                  <div
                    key={index}
                    className="flex items-center justify-between px-4 py-3.5 cursor-pointer hover:bg-[#E7E8EC] dark:hover:bg-primary-dark"
                  >
                    <div
                      onClick={() => {
                        setQuery(item);
                        handleSearch();
                      }}
                      className="flex items-center w-full overflow-hidden"
                    >
                      <svg
                        className="block w-5 h-5 text-gray-500 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 8v4m0 4h.01m6.938-4A8 8 0 1112 4a8 8 0 016.938 12z"
                        ></path>
                      </svg>
                      <p className="truncate text-ellipsis overflow-hidden w-full">
                        {item}
                      </p>
                    </div>
                    <button
                      className="text-gray-500 hover:text-gray-700"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents triggering the onClick for the entire item
                        handleRemoveHistoryItem(item);
                      }}
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                ))
            )} */}
          </div>

          {autocompleteSuggestions?.length > 0 && (
            <div
              className={` max-h-96 overflow-y-scroll absolute w-full  z-50 ${showHistory || searchResults
                ? "bg-white dark:bg-b-secondary-dark rounded-md border-t border-t-[#B5B5B5]"
                : ""
                }`}
            >
              {autocompleteSuggestions.map((suggestion: any, index: any) => (
                <div
                  key={index}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => handleAutocomplete(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}

          {showHistory && (
            <div
              className={`shadow-[1px_1px_15px_-2px_rgba(0,0,0,0.3)] overflow-y-scroll max-h-[88vh] absolute w-full  z-10 ${showHistory || searchResults
                ? "bg-white dark:bg-b-secondary-dark rounded-md border-t border-t-[#B5B5B5]"
                : ""
                }`}
            >
              <div className={"flex py-3.5"}>
                <div className={"w-3/4"}>
                  {searchResults?.mnas?.length === 0 &&
                    searchResults?.fundamentals?.length === 0 &&
                    searchResults?.notes?.length === 0 && (
                      <div className={"text-center"}>
                        <p>No data found</p>
                      </div>
                    )}
                  {/* MNAS */}
                  {searchResults?.mnas?.length > 0 ? (
                    <div className="px-2 py-2">
                      <ListTitle title={"MNAs"} />
                      {searchResults?.mnas.slice(0, 5).map((mna: any) => (
                        <ListItem
                          key={`mna-item-${mna.id}`}
                          id={mna.id}
                          Icon={<MnaIcon />}
                          handleNavigate={handleNavigate}
                          title={mna.ticker}
                          content={mna.dateModified}
                          path={"mna"}
                        />
                      ))}
                      {searchResults?.mnas?.length >= 5 && ViewAllResults()}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {/* FUNDAMENTALS */}
                  {searchResults?.fundamentals?.length > 0 && (
                    <div className="px-2 py-2">
                      <ListTitle title={"Fundamentals"} />
                      {searchResults.fundamentals
                        .slice(0, 5)
                        .map((fundamentals) => (
                          <ListItem
                            key={`fundamental-item-${fundamentals.id}`}
                            id={fundamentals.id}
                            Icon={<FundamentalIcon />}
                            handleNavigate={handleNavigate}
                            title={fundamentals.ticker}
                            content={fundamentals.dateModified}
                            path={"fundamental"}
                          />
                        ))}
                      {searchResults?.fundamentals?.length >= 5 &&
                        ViewAllResults()}
                    </div>
                  )}
                  {/*// NOTES*/}
                  {searchResults?.notes?.length > 0 && (
                    <div className="px-2 py-2">
                      <ListTitle title={"Notes"} />
                      {searchResults.notes.slice(0, 5).map((note: any) => (
                        <ListItem
                          key={`note-item-${note.id}`}
                          id={note.id}
                          Icon={<NotesIcon />}
                          handleNavigate={handleNavigate}
                          title={note.title || "Untitled Note"}
                          content={note.dateModified}
                          path={"notes"}
                          highlightedText={highlightText(note.highlightedText?.content[0])}
                        />
                      ))}
                      {searchResults?.notes?.length >= 5 && ViewAllResults()}
                    </div>
                  )}
                </div>
                <div className={"w-3/5 border-l border-l-b-gray px-2"}>
                  {/* FILTER BY DATE */}
                  <div className={"px-2 py-2"}>
                    <ListTitle title={"Last Updated"} />
                    <LastUpdatedButtonsList
                      dateFilter={dateFilter}
                      setDateFilter={setDateFilter}
                    />
                  </div>
                  {/* FILTER BY DOMAINS */}
                  <div className={"px-2 py-2"}>
                    <ListTitle title={"Filter by domains"} />
                    <FilterByDomainsList
                      setFilters={setFilters}
                      filters={filters}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ViewAllResults = (url?: string, search?: string): ReactNode => {
  return (
    <button className={"text-[#3248F2] font-normal text-[12px] pt-[7px] underline"}>
      View all results
    </button>
  );
};

export default ListSearch;
