// UpdatePassword.tsx
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomErrorModal,
  CustomSuccessModal,
} from "../../Components/Modal/customModal";
import { ErrorFileData } from "../../interfaces/pages/Errors";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Loader from "../../Components/Loader";
// Images
import EyeClose from "../../Assets/svg/eyesclose.svg";
import layer from "../../Assets/images/layour.png";
import login from "../../Assets/images/login.png";
import { apiConfig } from "../../Components/ConfigurationApi/Configuration";
import { UserAccountDomainApi } from "../../openapi";

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token: any = queryParams.get("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [serverError, setServerError] = useState<any>(null);

  const validationSchema = Yup.object().shape({
    password: Yup.string(),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match"
    ),
  });

  const formik: any = useFormik({
    initialValues: { password: "", passwordConfirm: "" },
    validationSchema,
    onSubmit: async (values: any, { setErrors }) => {
      try {
        setLoading(true);
        const api = new UserAccountDomainApi(apiConfig());
        const passwordResetRequest = values;
        const response = await api.userResetPassword(token, passwordResetRequest);
        if (response.status === 200) {
          setLoading(false);
          toast.custom(
            (t) => (
              <CustomSuccessModal
                visible={t.visible}
                message={response.data.message}
              />
            ),
            {
              duration: 500,
            }
          );
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      } catch (error: any) {
        if (error.response) {
          if (
            error.response.data?.exception &&
            !error.response.data?.fieldErrors?.length
          ) {
            toast.custom(
              (t) => <CustomErrorModal visible={t.visible} error={error} />,
              {
                duration: 500,
              }
            );
          }

          if (error.response.data?.fieldErrors?.length) {
            const errorsObject = {} as any;
            error.response.data?.fieldErrors.forEach((error: ErrorFileData) => {
              errorsObject[error.field] = error.defaultMsg;
            });
            setErrors(errorsObject);
            setServerError(errorsObject);
          }
        } else {
          // Handle other errors
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  return (
    <React.Fragment>
      <div className="flex h-screen relative">
        <div className="hidden md:block md:w-1/2 bg-gray-500 relative bg-[#F4F4FD]">
          <img
            src={layer}
            alt="Background"
            className="absolute h-screen w-full"
          />
          <div className="flex justify-center items-center h-full px-[68px]">
            <img src={login} alt="Background" />
          </div>
        </div>

        <div className="w-full md:w-1/2 p-8 flex items-center justify-center bg-gray-100">
          <form
            className="w-full"
            onSubmit={formik.handleSubmit}
            onChange={() => setServerError(null)}
          >
            <h2 className="text-primary text-[35px] font-bold uppercase mb-6">
              One68 Global capital
            </h2>
            <h2 className="text-[32px] text-black-2 font-semibold mb-8">
              Reset Password
            </h2>
            <div className="mb-[20px]">
              <div className="flex justify-between items-center">
                <label
                  htmlFor="Content"
                  className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1"
                >
                  Password <span className="text-[#DB1A21]">*</span>
                </label>

                <div className={`tooltip2`}>
                  <div
                    className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50 tooltiptext w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border ${
                      serverError?.password
                        ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
                        : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
                    }  tooltiptext2`}
                  >
                    {serverError?.password
                      ? serverError?.password
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
                  </div>
                  <svg
                    className={"dark:brightness-[4]"}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
                      fill={` ${serverError?.password ? "#DB1A21" : "#1E87F0"}`}
                    />
                    <path
                      d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
                      fill={` ${serverError?.password ? "#DB1A21" : "#1E87F0"}`}
                    />
                  </svg>{" "}
                </div>
              </div>
              <div className="relative">
                <input
                  name="password"
                  type={passwordType}
                  placeholder="Enter here..."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`py-[18px] px-[30px] border border-[#C3C1DF] rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none ${
                    serverError && serverError?.password ? "border-danger" : ""
                  } bg-["#FAFAFE"] dark:bg-primary-dark dark:text-white dark:border-white`}
                />

                <span
                  className="absolute right-7 top-5"
                  onClick={togglePasswordVisibility}
                >
                  {passwordType === "password" ? (
                    <img src={EyeClose} alt="eyeClose" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#7B7B7B"
                      className="w-6 h-6 dark:brightness-[4]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>
            <div className="mb-[20px]">
              <div className="flex justify-between items-center">
                <label
                  htmlFor="Content"
                  className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1"
                >
                  Confirm Password <span className="text-[#DB1A21]">*</span>
                </label>

                <div className={`tooltip2`}>
                  <div
                    className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50 tooltiptext w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border ${
                      serverError?.passwordConfirm
                        ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
                        : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
                    }  tooltiptext2`}
                  >
                    {serverError?.passwordConfirm
                      ? serverError?.passwordConfirm
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
                  </div>
                  <svg
                    className={"dark:brightness-[4]"}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
                      fill={` ${
                        serverError?.passwordConfirm ? "#DB1A21" : "#1E87F0"
                      }`}
                    />
                    <path
                      d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
                      fill={` ${
                        serverError?.passwordConfirm ? "#DB1A21" : "#1E87F0"
                      }`}
                    />
                  </svg>{" "}
                </div>
              </div>
              <div className="relative">
                <input
                  name="passwordConfirm"
                  type={confirmPasswordType}
                  placeholder="Enter here..."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirm}
                  className={`py-[18px] px-[30px] border border-[#C3C1DF] rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none ${
                    serverError && serverError?.passwordConfirm
                      ? "border-danger"
                      : ""
                  } bg-["#FAFAFE"] dark:bg-primary-dark dark:text-white dark:border-white`}
                />

                <span
                  className="absolute right-7 top-5"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {confirmPasswordType === "password" ? (
                    <img src={EyeClose} alt="eyeClose" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#7B7B7B"
                      className="w-6 h-6 dark:brightness-[4]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )}
                </span>
              </div>
              {formik.touched.passwordConfirm &&
              formik.errors.passwordConfirm &&
              !["must not be blank", "size must be between 8 and 255"].includes(
                formik.errors.passwordConfirm
              ) ? (
                <div className="py-1 text-sm font-medium text-danger">
                  {formik.errors.passwordConfirm}
                </div>
              ) : null}
            </div>

            <div className="mb-[40px]">
              <button
                type="submit"
                className="w-full  text-[#fff] bg-secondary py-[18px] rounded-full hover:bg-primary-default dark:bg-black"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default ResetPassword;
