// React
import React, { useCallback, useState } from "react";
// Components
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table2 from "../Components/Tables/Alert-Table";
// OpenAPI
import { AlertDomainApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";
// Images
import alertIcon from "../Assets/svg/blue-alert.svg";
import Close from "../Assets/svg/close.svg";

const AlertList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState<string | undefined>();

  const fetchAlertList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      try {
        const api = new AlertDomainApi(apiConfig());
        const response = await api.getAlerts(
          pageIndex,
          pageSize,
          getSortArray(sortBy)
        );
        return response.data;
      } catch (error) {
        expiredTokenValidation(error);
      }
    },
    []
  );

  const columnsInstance = new Columns();

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <div
        className={`py-[30px] pr-[30px] ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        <Breadcrumbs breadcrumbs={["Alerts"]} icon={alertIcon} />
        <div className="mt-[31px]">
          {/* <Table columns={columnsInstance.AlertList()} openModal={openModal} additionalDataKey="content" fetchData={fetchAlertList} alers={true} /> */}
          <Table2
            columns={columnsInstance.AlertList()}
            fetchData={fetchAlertList}
            additionalDataKey="content"
            openModal={openModal}
          />
        </div>
      </div>
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="close"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AlertList;
